import * as React from "react";
import {
  createTheme,
  responsiveFontSizes,
  Theme,
  ThemeProvider as MuiThemProvider,
  ThemeOptions,
  CssBaseline,
} from "@mui/material";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { ThemeProviderProps } from "@mui/material/styles/ThemeProvider";
import { EmotionCache, ThemeProvider } from "@emotion/react";
import tokens from "@tbml/tokens";
import { useBrandColor } from "@tbml/hooks/useBrandColor";
import { GlobalStyles } from "./GlobalStyles";
import { darkPalette, lightPalette } from "./palette";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    beadcrumb: true;
    tab: true;
    longTextDefault: true;
    longTextSmall: true;
    shortTextDefault: true;
    shortTextLarge: true;
    shortTextSmall: true;
    shortTextXsmall: true;
  }
}

const themeOptions: Omit<ThemeOptions, "palette"> = {
  breakpoints: Object.entries(tokens.breakpoint).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value.minWidth.value,
    }),
    {}
  ),
  typography: Object.entries(tokens.font).reduce<TypographyOptions>(
    (acc, [variant, values]) => ({
      ...acc,
      [variant]: {
        fontFamily: `${
          values.fontFamily.value
        }, ${tokens.fallbackFont.fontFamilies.value.join(", ")}`,
        fontSize: values.fontSize.value,
        fontWeight: values.fontWeight.value,
        textDecoration: values.textDecoration.value,
        fontStyle: values.fontStyle.value,
        fontStretch: values.fontStretch.value,
        letterSpacing: values.letterSpacing.attributes.em,
        lineHeight:
          values.lineHeight.unit === "percent"
            ? values.lineHeight.original.value / 100
            : values.lineHeight.value,
        textIndent: values.paragraphIndent.value,
        padding: values.paragraphSpacing.value,
        textTransform: values.textCase.value,
      },
    }),
    {}
  ),
  shape: {
    borderRadius: tokens.radii.rounded.radius.original.value, // https://github.com/mui/material-ui/pull/34076/files
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedSecondary: {
          backgroundColor: tokens.color.backgroundDefault.value,
          color: tokens.color.textPrimary.value,

          "&:hover": {
            backgroundColor: tokens.color.backgroundPaper.value,
          },
          "&:focused": {
            backgroundColor: tokens.color.backgroundPaper.value,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&$focused": {
            color: tokens.color.textSecondary.value,
            fontWeight: "bold",
          },
        },

        focused: {},
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "none",
      },
    },
    MuiRadio: {
      styleOverrides: {
        colorSecondary: ({ theme }) => ({
          "&$checked": {
            color: theme.palette.primary.main,
          },
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: tokens.sizes.textField.value,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          width: tokens.sizes.textField.value,
        },
        paper: {
          width: tokens.sizes.textField.value,
        },
        listbox: {
          wordBreak: "break-word",
          backgroundColor: tokens.color.backgroundDefault.value,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ theme, ownerState: { color } }) => ({
          color: color ?? theme.palette.primary.main,
        }),
        indicator: ({ ownerState: { color } }) => ({
          backgroundColor: color,
        }),
        scrollButtons: {
          "&.Mui-disabled": { opacity: 0.3 },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          opacity: 0.75,
          transition: theme.transitions.create(["filter", "opacity"]),
          ":hover": {
            opacity: 1,
          },
          "&.Mui-selected": {
            opacity: 1,
            color: "inherit",
          },
        }),
      },
    },
  },
};

export const tbmlLightTheme = responsiveFontSizes(
  createTheme({ ...themeOptions, palette: lightPalette })
);
export const tbmlDarkTheme = responsiveFontSizes(
  createTheme({
    ...themeOptions,
    palette: darkPalette,
  })
);

export const BrandColorContext = React.createContext<string>(
  tokens.color.brandMain.value
);

export function BrandColorFetcher({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const { data, status } = useBrandColor({
    fallbackColor: tokens.color.brandMain.value,
  });
  const [customer] = data ?? [];
  const brandColor = customer?.brandColor ?? tokens.color.brandMain.value;
  const color =
    status === "pending"
      ? tokens.color.textSecondaryDarkTheme.value
      : brandColor;

  return (
    <BrandColorContext.Provider value={color}>
      {children}
    </BrandColorContext.Provider>
  );
}

export type TbmlThemeProps = Partial<
  ThemeProviderProps & { mode?: "dark" | "light"; variant?: "regular" | "alt" }
>;

export function TbmlTheme({
  mode = "light",
  children = undefined,
  variant,
  ...props
}: React.PropsWithChildren<TbmlThemeProps>): JSX.Element {
  const brandColor = React.useContext(BrandColorContext);

  const theme = React.useMemo(() => {
    const modeTheme: Theme = mode === "light" ? tbmlLightTheme : tbmlDarkTheme;
    // https://mui.com/material-ui/customization/palette/#providing-the-colors-directly
    const brandThemeOptions: ThemeOptions = {
      ...modeTheme,
      palette: {
        ...modeTheme.palette,
        ...(variant === "alt"
          ? {
              background: {
                paper: tokens.color.backgroundDefault.value,
                default: tokens.color.backgroundPaper.value,
                page: tokens.color.backgroundPage.value,
              },
            }
          : {}),
        primary: {
          // light: will be calculated from palette.primary.main,
          main: brandColor,
          // dark: will be calculated from palette.primary.main,
          // contrastText: will be calculated to contrast with palette.primary.main
        },
      },
    };
    return createTheme(brandThemeOptions);
  }, [brandColor, mode, variant]);

  return (
    <ThemeProvider {...props} theme={theme}>
      <MuiThemProvider {...props} theme={theme}>
        {children}
      </MuiThemProvider>
    </ThemeProvider>
  );
}

export type TbmlStylingProps = Partial<ThemeProviderProps> & {
  cache: EmotionCache;
} & {
  mode?: "dark" | "light";
};

export function TbmlStyling({
  children = undefined,
  cache,
  ...props
}: React.PropsWithChildren<TbmlStylingProps>): JSX.Element {
  return (
    <TbmlTheme mode="light" variant="regular" {...props}>
      <CssBaseline />
      <GlobalStyles />
      {children}
    </TbmlTheme>
  );
}
