import * as React from "react";
import { GlobalStyles as GlobalStylesComponent, useTheme } from "@mui/material";

export function GlobalStyles(): JSX.Element {
  const { palette } = useTheme();
  return (
    <GlobalStylesComponent
      styles={`
      html,
      body {
        background-color: ${palette.background.page};
        height: 100%;
        padding: 0;
        margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
          Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
      }

      a {
        color: inherit;
        text-decoration: none;
      }

      * {
        box-sizing: border-box;
      }

      #__next {
        height: 100%;
      }
    `}
    />
  );
}
