import * as React from "react";
import {
  CircularProgress,
  styled,
  ListItemIcon,
  ListItem,
  List,
  Card,
  CardHeader,
  Button,
  CardContent,
  IconButton,
  ListItemText,
} from "@mui/material";
import {
  PlayCircle as PlayCircleIcon,
  CheckCircle as CheckCircleIcon,
  Dashboard as DashboardIcon,
  KeyboardArrowLeft,
  ChevronRight,
} from "@mui/icons-material";
import { signOut } from "next-auth/react";
import { gql } from "graphql-request";
import { useCurrentCustomerId } from "@tbml/hooks/useCurrentCustomerId";
import { useCustomers } from "@tbml/hooks/useCustomers";
import tokens from "@tbml/tokens";
import { Spacer } from "@tbml/components/Spacer";
import { useRouter } from "@tbml/shared-dependencies/router";
import { useShouldShowDebugPanel } from "../../hooks/useShouldShowDebugPanel";

const DebugPanelPositioner = styled(Card)`
  box-shadow: ${({ theme }) => {
    if (!theme || !theme.transitions) return false;
    const {
      shadows: [, firstShadow],
    } = theme;
    return firstShadow;
  }};
  position: fixed;
  padding: ${tokens.spacing.gutterS.value};
  z-index: 1000;
  bottom: 10px;
  right: 10px;
  max-height: calc(100% - 12em);
  overflow-y: auto;
`;

export type Props = {
  mode: "light" | "dark";
  setMode: React.Dispatch<React.SetStateAction<"light" | "dark">>;
};

const isValidCustomerId = (customerId: unknown): customerId is string =>
  typeof customerId === "string";

export const debugPanelCustomerFragmentName = "DebugPanelCustomers";
export const debugPanelCustomerFragment = gql`
  fragment DebugPanelCustomers on Customer {
    id
    name
  }
`;

export function DebugPanel({ mode, setMode }: Props): JSX.Element | null {
  const { query, push } = useRouter();
  const {
    isInternalUser,
    isLoading: internalUserLoading,
    shouldShowDebugPanel,
  } = useShouldShowDebugPanel();
  const { query: queryCustomers } = useCustomers();

  const isInternalUserWithCustomerQuery =
    !internalUserLoading &&
    isInternalUser &&
    isValidCustomerId(query.customerId);

  const enabled = !internalUserLoading && isInternalUser;

  const { data, status } = queryCustomers({
    ...(isInternalUserWithCustomerQuery
      ? {
          filter: { ids: [query.customerId as string] },
          fragmentName: debugPanelCustomerFragmentName,
          fragment: debugPanelCustomerFragment,
        }
      : {
          fragmentName: debugPanelCustomerFragmentName,
          fragment: debugPanelCustomerFragment,
        }),
    enabled,
  });
  const [currentCustomerId, setCurrentCustomerId] = useCurrentCustomerId();
  const [isMinimized, setIsMinimized] = React.useState(!!currentCustomerId);

  React.useEffect(() => {
    if (data?.length && !currentCustomerId) {
      setCurrentCustomerId(data[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setCurrentCustomerId]);

  React.useEffect(() => {
    const defaultMinimized = !isInternalUser;

    if (isMinimized !== defaultMinimized) {
      setIsMinimized(defaultMinimized);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInternalUser]);

  if (!shouldShowDebugPanel) {
    return null;
  }

  return (
    <DebugPanelPositioner elevation={11}>
      {isMinimized ? (
        <IconButton
          edge="end"
          aria-label="toggle"
          onClick={() => {
            setIsMinimized(false);
          }}
        >
          <KeyboardArrowLeft />
          <DashboardIcon />
        </IconButton>
      ) : (
        <>
          <CardHeader title="Customer Selector" />
          <CardContent>
            <List>
              {status === "pending" ? (
                <CircularProgress />
              ) : (
                data?.map((customer) => (
                  <ListItem
                    key={customer.id}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={async () => {
                          await push("..");
                          setCurrentCustomerId(customer.id);
                          setIsMinimized(true);
                        }}
                      >
                        <PlayCircleIcon />
                      </IconButton>
                    }
                  >
                    {currentCustomerId === customer.id && (
                      <ListItemIcon>
                        <CheckCircleIcon />
                      </ListItemIcon>
                    )}
                    <ListItemText>{customer.name}</ListItemText>
                  </ListItem>
                ))
              )}
            </List>
            <Spacer />
            <Button
              onClick={() =>
                setMode((prevState) =>
                  prevState === "light" ? "dark" : "light"
                )
              }
            >
              {mode === "light"
                ? "Set theme to 🦉 mode"
                : "Set theme to ☀️ mode"}
            </Button>
            <Spacer />
            <Button onClick={() => signOut()}>Sign out</Button>
            <Spacer />
            <IconButton
              edge="end"
              aria-label="toggle debug panel"
              onClick={() => {
                setIsMinimized(true);
              }}
            >
              <DashboardIcon />
              <ChevronRight />
            </IconButton>
          </CardContent>
        </>
      )}
    </DebugPanelPositioner>
  );
}
