import { gql, GraphQLClient } from "graphql-request";
import { QueryObserverResult, useQuery } from "@tanstack/react-query";
import { useApi } from "../useApi";

export const isInternalUserQueryFn =
  (client: GraphQLClient) => async (): Promise<boolean> => {
    const { getIsInternalUser } = await client.request<{
      getIsInternalUser: boolean;
    }>(
      gql`
        query GetIsInternalUser {
          getIsInternalUser
        }
      `
    );
    return getIsInternalUser;
  };

export const useIsInternalUser = (): QueryObserverResult<boolean, Error> => {
  const { client, token } = useApi();

  return useQuery<boolean, Error>({
    queryKey: ["isInternalUser"],
    queryFn: isInternalUserQueryFn(client),
    enabled: !!token,
  });
};
