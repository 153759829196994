/* eslint-disable @20minutes/graphql/template-strings */
import { gql, GraphQLClient } from "graphql-request";
import {
  QueryObserverResult,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  Customer,
  CustomerFilter,
  CustomerInput,
  CustomerUpdateInput,
  IssueOutput,
  Template,
} from "@tbml/api-interface/graphql";
import {
  isLicenseError,
  isLicenseFreeError,
} from "@tbml/shared-dependencies/license";
import { useApi } from "../useApi";
import { UseCustomersFieldsFragment } from "./fields";

export type UseCustomersResult = {
  query: (
    args:
      | {
          filter?: CustomerFilter;
          fragmentName?: never;
          fragment?: never;
          enabled?: boolean;
        }
      | {
          filter?: CustomerFilter;
          fragmentName: string;
          fragment: string;
          enabled?: boolean;
        }
  ) => QueryObserverResult<Customer[], Error>;
  mutator: UseMutationResult<Customer, Error, CustomerInput>;
  updater: UseMutationResult<
    Customer[],
    Error,
    { set: CustomerUpdateInput; filter: CustomerFilter }
  >;
  deleter: UseMutationResult<void, Error, CustomerFilter>;
};

export const customerQueryFn =
  (
    client: GraphQLClient,
    filter: CustomerFilter = {},
    fragmentName = "UseCustomersFields",
    fragment = UseCustomersFieldsFragment
  ) =>
  async (): Promise<Customer[]> => {
    const {
      getCustomers: { customers },
    } = await client.request<
      { getCustomers: { customers: Customer[] } },
      { filter: CustomerFilter }
    >(
      gql`
        query GetCustomers($filter: CustomerFilter!) {
          getCustomers(filter: $filter) {
            customers {
              ...${fragmentName}
            }
          }
        }
        ${fragment}
      `,
      { filter }
    );

    return customers;
  };

export const useCustomers = (
  {
    fragmentName = "UseCustomersFields",
    fragment = UseCustomersFieldsFragment,
  }: {
    fragmentName: string;
    fragment: string;
  } = {
    fragmentName: "UseCustomersFields",
    fragment: UseCustomersFieldsFragment,
  }
): UseCustomersResult => {
  const { client, token } = useApi();
  const queryClient = useQueryClient();

  const useCustomersQuery = ({
    filter,
    enabled = true,
    fragmentName: queryFragmentName = fragmentName,
    fragment: queryFragment = fragment,
  }:
    | {
        filter?: CustomerFilter;
        fragmentName?: never;
        fragment?: never;
        enabled?: boolean;
      }
    | {
        filter?: CustomerFilter;
        fragmentName: string;
        fragment: string;
        enabled?: boolean;
      }) =>
    useQuery<Customer[], Error>({
      queryKey: ["customers", filter ?? {}, queryFragmentName],
      queryFn: customerQueryFn(
        client,
        filter ?? {},
        queryFragmentName,
        queryFragment
      ),
      enabled: !!token && enabled,
      retry: (failureCount, error) => {
        if (isLicenseFreeError(error)) return false;
        if (isLicenseError(error)) return false;
        return failureCount !== 3;
      },
    });

  const updater = useMutation<
    Customer[],
    Error,
    { set: CustomerUpdateInput; filter: CustomerFilter }
  >({
    mutationFn: async ({ set, filter }) => {
      const {
        updateCustomer: { customers },
      } = await client.request<{ updateCustomer: { customers: Customer[] } }>(
        gql`
          mutation UpdateCustomer(
            $set: CustomerUpdateInput!
            $filter: CustomerFilter!
          ) {
            updateCustomer(set: $set, filter: $filter) {
              customers {
                ...${fragmentName}
              }
            }
          }
          ${fragment}
        `,
        { set, filter }
      );
      return customers;
    },
    onMutate: ({ set: updatedFields, filter }) => {
      if (updatedFields.defaultIssue) {
        // update issues
        const issuesCache = queryClient.getQueriesData<IssueOutput>({
          queryKey: ["issues"],
        });
        issuesCache.forEach(([key, issueOutput]) => {
          if (!issueOutput) return;
          queryClient.setQueryData<IssueOutput>(key, () => ({
            ...issueOutput,
            issues: issueOutput.issues.map((issue) =>
              issue.id === updatedFields.defaultIssueId
                ? {
                    ...issue,
                    backlogOrder:
                      updatedFields.defaultIssue?.backlogOrder ??
                      issue.backlogOrder,
                    publicationStatus:
                      updatedFields.defaultIssue?.publicationStatus ??
                      issue.publicationStatus,
                  }
                : issue
            ),
          }));
        });
      }

      // update customers
      const customerCache = queryClient.getQueriesData<Customer[]>({
        queryKey: ["customers"],
      });
      customerCache.forEach(([key, customerOutput]) => {
        queryClient.setQueryData<Customer[]>(key, () =>
          (customerOutput ?? []).map((customer) => {
            if (!customer) return customer;
            if (filter.ids && !filter.ids.includes(customer.id))
              return customer;

            return {
              ...customer,
              ...updatedFields,
              language: updatedFields.language ?? customer.language,
              contactListIds:
                updatedFields.contactListIds ?? customer.contactListIds,
              name: updatedFields.name ?? customer.name,
              template:
                (updatedFields.template as Template) ?? customer.template,
              templateId: updatedFields.template?.id ?? customer.templateId,
              defaultIssue: customer.defaultIssue
                ? {
                    ...customer.defaultIssue,
                    backlogOrder:
                      updatedFields.defaultIssue?.backlogOrder ??
                      customer.defaultIssue.backlogOrder,
                    publicationStatus:
                      updatedFields.defaultIssue?.publicationStatus ??
                      customer.defaultIssue.publicationStatus,
                  }
                : null,
              image: updatedFields.imageFile ? null : customer.image,
              roles:
                updatedFields.roles !== undefined
                  ? (updatedFields.roles as string[])
                  : customer.roles,
              inboxEbsId: updatedFields.inboxEbsId ?? customer.inboxEbsId,
              inboxAddcovId:
                updatedFields.inboxAddcovId ?? customer.inboxAddcovId,
              inboxSocialMediaId:
                updatedFields.inboxSocialMediaId ?? customer.inboxSocialMediaId,
              executiveBriefingTopics:
                updatedFields.executiveBriefingTopics?.map((topic) => ({
                  ...topic,
                  subTitle: topic.subTitle ?? null,
                  customerId: customer.id,
                  deletedAt: null, // TODO not sure if we really need this
                  stories: [], // and this, probably it's only here to fix TS
                })) ?? customer.executiveBriefingTopics,
              contactInfoEmail:
                updatedFields.contactInfoEmail ?? customer.contactInfoEmail,
              contactInfoName:
                updatedFields.contactInfoName ?? customer.contactInfoName,
              contactInfoIncluded:
                updatedFields.contactInfoIncluded ??
                customer.contactInfoIncluded,
              audioIssueEnabled:
                updatedFields.audioIssueEnabled ?? customer.audioIssueEnabled,
              enableAnIContent:
                updatedFields.enableAnIContent ?? customer.enableAnIContent,
              gloriaCustomerId:
                updatedFields.gloriaCustomerId ?? customer.gloriaCustomerId,
              selectedWidgets:
                updatedFields.selectedWidgets ?? customer.selectedWidgets,
            };
          })
        );
      });
    },
    onError: () => {
      queryClient.invalidateQueries({
        queryKey: ["issues"],
      });
      queryClient.invalidateQueries({ queryKey: ["customers"] });
    },
    onSuccess: (customers, { filter }) => {
      queryClient.invalidateQueries({ queryKey: ["customers", {}] });
      queryClient.invalidateQueries({ queryKey: ["customers", filter] });

      // get cache
      const cachedCustomers =
        queryClient.getQueryData<Customer[]>([
          "customers",
          filter,
          fragmentName,
        ]) ?? [];

      const updatedCustomers = [
        ...cachedCustomers.map((cachedCustomer) => {
          const updatedCustomer = customers.find(
            (customer) => customer.id === cachedCustomer.id
          );
          if (updatedCustomer) {
            return { ...cachedCustomer, ...updatedCustomer };
          }
          return cachedCustomer;
        }),
        ...customers.filter(
          (customer) =>
            !cachedCustomers.find(
              (cachedCustomer) => cachedCustomer.id === customer.id
            )
        ),
      ];

      queryClient.setQueryData(
        ["customers", filter, fragmentName],
        updatedCustomers
      );
    },
  });

  const mutator = useMutation<Customer, Error, CustomerInput>({
    mutationFn: async (input) => {
      const {
        mutateCustomer: {
          customers: [customer],
        },
      } = await client.request<{ mutateCustomer: { customers: Customer[] } }>(
        gql`
          mutation MutateCustomer($input: CustomerInput!) {
            mutateCustomer(input: $input) {
              customers {
                ...${fragmentName}
              }
            }
          }
          ${fragment}
        `,
        { input }
      );
      return customer;
    },
    onSuccess: (customer) => {
      queryClient.invalidateQueries({ queryKey: ["customers", {}] });
      queryClient.invalidateQueries({
        queryKey: ["customers", { ids: [customer.id] }],
      });
      queryClient.setQueryData(
        ["customers", { ids: [customer.id] }, fragmentName],
        [customer]
      );
    },
  });

  const deleteCustomers = useMutation<void, Error, CustomerFilter>({
    mutationFn: async (filter) => {
      if (!filter.ids) return;
      await client.request(
        gql`
          mutation DeleteCustomer($filter: CustomerFilter!) {
            deleteCustomer(filter: $filter) {
              customers {
                id
              }
            }
          }
        `,
        { filter }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["customers"] });
    },
  });

  return {
    query: useCustomersQuery,
    mutator,
    updater,
    deleter: deleteCustomers,
  };
};
