import { gql } from "graphql-request";
import { UseArticlesFieldsFragment } from "../useArticles/fields";

export const UseStoriesFieldsFragment = gql`
  fragment UseStoriesFields on Story {
    id
    title
    actionButtonIncluded
    actionButtonName
    actionButtonLink
    image {
      id
      originPath
      mediaWidth
      mediaHeight
      allowCropping
      showOriginalSize
    }
    broadcastData {
      publicUrl
      url
      duration
      id
      tvEyesSearchId
      stationId
    }
    editorial
    issueId
    articleOrder
    articles {
      ...UseArticlesFieldsFragment
    }
    executiveBriefingTopicId
    executiveBriefingTopic {
      id
      title
      subTitle
      customerId
    }
    allAssets {
      articleBroadcastData {
        article {
          customHeadline
          headline
          id
        }
        duration
        id
        publicUrl
        stationId
        tvEyesSearchId
        url
      }
      asset {
        dateCreated
        id
        mediaHeight
        mediaWidth
        originPath
        showOriginalSize
        sourceId
      }
      articleImage {
        id
        article {
          customHeadline
          headline
          id
        }
        selected
        url
      }
      id
      selected
    }
  }
  ${UseArticlesFieldsFragment}
`;
export const AFragment = gql`
  fragment DebugPanelStories on Story {
    id
    title
  }
`;

export const UseStoriesInsideTopicFieldsFragment = gql`
  fragment UseStoriesInsideTopicFields on StoryInsideTopic {
    id
    title
    image {
      id
      originPath
      mediaWidth
      mediaHeight
      allowCropping
      showOriginalSize
    }
    editorial
    issueId
    articles {
      ...UseArticlesFieldsFragment
    }
    executiveBriefingTopicId
  }
  ${UseArticlesFieldsFragment}
`;
