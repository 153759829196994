import { gql } from "graphql-request";
import { QueryObserverResult } from "@tanstack/react-query";
import { Customer } from "@tbml/api-interface/graphql";
import { useCustomers } from "../useCustomers";
import { useCurrentCustomerId } from "../useCurrentCustomerId";

export const customerBrandColorFragmentName = "CustomerBrandColorField";
export const customerBrandColorFragment = gql`
  fragment CustomerBrandColorField on Customer {
    brandColor
  }
`;

export const useBrandColor = (
  {
    enabled = true,
    fallbackColor,
  }: {
    enabled?: boolean;
    fallbackColor?: string;
  } = { enabled: true }
): QueryObserverResult<Customer[], Error> => {
  const [customerId] = useCurrentCustomerId();

  const result = useCustomers({
    fragment: customerBrandColorFragment,
    fragmentName: customerBrandColorFragmentName,
  }).query({
    filter: {
      ids: customerId ? [customerId] : [],
    },
    enabled: !!customerId && enabled,
  });

  const [customer] = result.data ?? [];

  if (result.status === "success" && !customer?.brandColor) {
    return {
      ...result,
      data: [{ ...customer, brandColor: fallbackColor ?? null }],
    };
  }

  return result;
};
