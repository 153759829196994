import { styled } from "@mui/material";
import tokens from "@tbml/tokens";

export type Props = {
  size?: keyof typeof tokens.spacing;
  inline?: boolean;
};

const spacerSize = ({ size }: Props) =>
  size ? tokens.spacing[size].value : tokens.spacing.gutterM.value;

export const Spacer = styled("div", {
  shouldForwardProp: (prop) => prop !== "size" && prop !== "inline",
})<Props>`
  display: ${({ inline }) => inline && "inline-block"};
  width: ${spacerSize};
  height: ${spacerSize};
  flex-shrink: 0;
`;
