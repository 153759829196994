import { Theme } from "@mui/material";

export const none = `
  transition: none;
`;

export const fade = (theme: Theme): string => `
  transition: opacity ${theme.transitions.duration.standard}ms;
  will-change: opacity;
  backface-visibility: hidden;

  &.enter {
    opacity: 0;
  }

  &.enter-active,
  &.enter-done {
    opacity: 1;
  }
`;

export const slideLeft = (theme: Theme): string => `
  will-change: transform;
  backface-visibility: hidden;
  
  // Support for all Browsers except IOS
  @supports not (-webkit-touch-callout: none) {
    transition: transform ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut};
  }
  // Only for IOS
  @supports (-webkit-touch-callout: none) {
    transition: transform ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}, visibility 1ms;
    
    &.enter {
      visibility: collapse;
    }
    &.enter-active {
      visibility: visible;
    }
  }
  
  &.enter {
    transform: translateX(100vw);
  }
  
  &.enter-active,
  &.enter-done {
    transform: translateX(0);
  }
`;

export const slideRight = (theme: Theme): string => `
  @supports not (-webkit-touch-callout: none) {
    transition: transform ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut};
    &.exit {
      transform: translateX(0);
    }
    
    &.exit-active,
    &.exit-done {
      transform: translateX(100vw);
    }
  }
  @supports (-webkit-touch-callout: none) {
    position: relative;
    transition: left ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut};
    
    &.exit {
      left: 0 !important;
    }
    
    &.exit-active,
    &.exit-done {
      left: 100vw !important;
    }
  }
`;

export const slideBottom = (theme: Theme): string => `
  transition: transform ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut};
  will-change: transform;
  backface-visibility: hidden;
  
  &.enter {
    transform: translateY(-100vh);
  }

  &.enter-active,
  &.enter-done {
    transform: translateY(0);
  }
  
  &.exit {
    transform: translateY(0);
  }

  &.exit-active,
  &.exit-done {
    transform: translateY(100vh);
  }
`;

export const slideTop = (theme: Theme): string => `
  transition: transform ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut};
  will-change: transform;
  backface-visibility: hidden;
  
  &.enter {
    transform: translateY(100vh);
  }

  &.enter-active,
  &.enter-done {
    transform: translateY(0);
  }
  
  &.exit {
    transform: translateY(0);
  }

  &.exit-active,
  &.exit-done {
    transform: translateY(-100vh);
  }
`;
