import React, { useEffect } from "react";
import { Button, Stack, useMediaQuery, useTheme } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import throttle from "lodash/throttle";
// eslint-disable-next-line workspaces/no-cross-imports,workspaces/require-dependency
import { useShouldShowDebugPanel } from "@tbml/viewer/hooks/useShouldShowDebugPanel";
import tokens from "@tbml/tokens";
import {
  ScrollSection,
  useCurrentScrollSectionId,
  useScrollSections,
} from "./useScrollSection";

const TOLERANCE = 20;

const useSyncCurrentSection = ({ isEnabled }: { isEnabled: boolean }) => {
  const [, setCurrentScrollSectionId] = useCurrentScrollSectionId();
  const [scrollSections] = useScrollSections();

  useEffect(() => {
    const handleScroll = () => {
      const { scrollY } = window;
      const sectionIndexBelow = scrollSections.findIndex(
        // start sections little above
        (section) => section.y > scrollY + TOLERANCE
      );

      // we haven't scrolled to last section, but we cannot scroll more (case for footer)
      const lastSection = scrollSections.at(-1);
      if (sectionIndexBelow === scrollSections.length - 1 && lastSection) {
        const currentBottom = scrollY + window.innerHeight;
        const diff = Math.abs(currentBottom - lastSection.yEnd);
        // in some cases when using Cypress, the scrollY values
        // can contain "*.5". This allows some more flexibility.
        if (diff <= 1) {
          setCurrentScrollSectionId(lastSection.elementId);
          return;
        }
      }

      const newSection =
        sectionIndexBelow === -1
          ? scrollSections.at(-1)
          : scrollSections[Math.max(sectionIndexBelow - 1, 0)];

      if (newSection?.elementId) {
        setCurrentScrollSectionId(newSection.elementId);
      }
    };

    const handleScrollThrottled = throttle(handleScroll, 100);

    if (isEnabled) {
      window.addEventListener("scroll", handleScrollThrottled);
      handleScroll(); // initial sync
    }
    return () => {
      if (isEnabled) {
        window.removeEventListener("scroll", handleScrollThrottled);
      }
    };
  }, [isEnabled, scrollSections, setCurrentScrollSectionId]);
};

export function ScrollSections(): JSX.Element | null {
  const [scrollSections] = useScrollSections();
  const [currentScrollSectionId, setCurrentScrollSectionId] =
    useCurrentScrollSectionId();

  const areSyncSectionsEnabled = scrollSections.length > 1;

  useSyncCurrentSection({
    isEnabled: areSyncSectionsEnabled, // footer has sort section id
  });

  const { shouldShowDebugPanel } = useShouldShowDebugPanel();

  const theme = useTheme();
  const isVerticalLayout = useMediaQuery(theme.breakpoints.down("md"));

  if (!areSyncSectionsEnabled) return null;

  const currentScrollSectionIdIndex = scrollSections.findIndex(
    ({ elementId }) => elementId === currentScrollSectionId
  );

  const handleGoToNextSection = (section: ScrollSection) => {
    const element = document.getElementById(section.elementId);
    if (!element) return;
    setCurrentScrollSectionId(section.elementId);
    // section.ref.current.scrollTo({ behavior: "smooth" });
    element.scrollIntoView();
  };
  const handleUp = () => {
    const nextScrollSection = scrollSections[currentScrollSectionIdIndex - 1];
    handleGoToNextSection(nextScrollSection);
  };
  const handleDown = () => {
    const nextScrollSection = scrollSections[currentScrollSectionIdIndex + 1];
    handleGoToNextSection(nextScrollSection);
  };

  return (
    <Stack
      id="scroll-sections"
      direction="column"
      alignItems="center"
      border={1}
      borderColor="primary.main"
      borderRadius={40}
      boxShadow={5}
      width={32}
      overflow="hidden"
      bottom={shouldShowDebugPanel ? 100 : 20}
      sx={{
        background: tokens.color.backgroundDefault.value,
        position: "fixed",
        right: 20,
        zIndex: 1,
        ...(!isVerticalLayout && {
          opacity: 0.3,
          transition: "opacity 0.3s",
          "&:hover": {
            opacity: 1,
          },
        }),
      }}
    >
      <Button
        aria-label="up"
        disabled={currentScrollSectionIdIndex === 0}
        onClick={handleUp}
      >
        <KeyboardArrowUp />
      </Button>
      <Button
        aria-label="down"
        disabled={currentScrollSectionIdIndex === scrollSections.length - 1}
        onClick={handleDown}
      >
        <KeyboardArrowDown />
      </Button>
    </Stack>
  );
}

export default ScrollSections;
