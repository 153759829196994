import * as Sentry from "@sentry/nextjs";
import { showReportDialog } from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

export default (runtimeEnvironment, apiUrl) => {
  Sentry.init({
    environment: runtimeEnvironment,
    dsn: "https://ae9dcaa5d6c740358669a2512b073e3a@o668009.ingest.sentry.io/5767224",
    integrations: [
      new Integrations.BrowserTracing({ tracingOrigins: [apiUrl] }),
    ],
    tracesSampleRate: 0.1,
    ignoreErrors: ["cancelRouteChange", "Load failed", "Aborted"],
    beforeSend(event) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        showReportDialog({ eventId: event.event_id });
      }
      return event;
    },
  });
};
