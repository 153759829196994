import * as React from "react";
import PageSwapperComponent from "@moxy/react-page-swapper";
import { styled } from "@mui/material";
import { usePageTransition } from "@tbml/hooks/usePageTransition";
import { useRouter } from "@tbml/shared-dependencies/router";
import { useScrollRestore } from "./useScrollRestore";

const InnerPageSwapper = styled(PageSwapperComponent, {
  shouldForwardProp: (prop) => prop !== "swapping",
})<{ swapping: boolean }>`
  width: 100%;
  overflow: ${({ swapping }) => swapping && "hidden"};
`;

export function PageSwapper({
  onSwapBegin = () => {},
  onSwapEnd = () => {},
  ...props
}: Parameters<typeof PageSwapperComponent>[0]): JSX.Element {
  const router = useRouter();
  const { lock, setAnimationName, setLock } = usePageTransition();

  const { setScroll } = useScrollRestore();

  React.useEffect(() => {
    router.beforePopState((state) => {
      setAnimationName("none");
      // add this to prevent NextJS from doing anything with scrolling
      // eslint-disable-next-line no-param-reassign
      state.options.scroll = false;
      return true;
    });
  }, [router, setAnimationName]);

  return (
    <InnerPageSwapper
      {...props}
      swapping={lock}
      updateScroll={setScroll}
      onSwapBegin={() => {
        setLock(true);
        if (onSwapBegin) onSwapBegin();
      }}
      onSwapEnd={() => {
        setLock(false);
        if (onSwapEnd) onSwapEnd();
      }}
    />
  );
}
