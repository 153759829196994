import { ThemeOptions } from "@mui/material";
import tokens from "@tbml/tokens";

declare module "@mui/material/styles" {
  interface TypeBackground {
    page: string;
  }
}

export const lightPalette: ThemeOptions["palette"] = {
  mode: "light",
  primary: {
    main: tokens.color.brandMain.value,
    light: tokens.color.brandLight.value,
    dark: tokens.color.brandDark.value,
    // contrastText: tokens.color.on,
  },
  error: {
    main: tokens.color.errorMain.value,
    light: tokens.color.errorLight.value,
    dark: tokens.color.errorDark.value,
    // contrastText: tokens.color.onError.value,
  },
  warning: {
    main: tokens.color.warningMain.value,
    light: tokens.color.warningLight.value,
    dark: tokens.color.warningDark.value,
    // contrastText: tokens.color.onWarning.value,
  },
  info: {
    main: tokens.color.infoMain.value,
    light: tokens.color.infoLight.value,
    dark: tokens.color.infoDark.value,
    // contrastText: tokens.color.onInfo.value,
  },
  success: {
    main: tokens.color.successMain.value,
    light: tokens.color.successLight.value,
    dark: tokens.color.successDark.value,
    // contrastText: tokens.color.onSuccess.value,
  },
  background: {
    default: tokens.color.backgroundDefault.value,
    paper: tokens.color.backgroundPaper.value,
    page: tokens.color.backgroundPage.value,
  },
  text: {
    primary: tokens.color.textPrimary.value,
    secondary: tokens.color.textSecondary.value,
    disabled: tokens.color.textDisabled.value,
  },
  divider: tokens.color.divider.value,
};

export const darkPalette: ThemeOptions["palette"] = {
  mode: "dark",
  primary: {
    main: tokens.color.brandMain.value,
    light: tokens.color.brandLight.value,
    dark: tokens.color.brandDark.value,
    // contrastText: tokens.color.onPrimary.value,
  },
  error: {
    main: tokens.color.errorMain.value,
    light: tokens.color.errorLight.value,
    dark: tokens.color.errorDark.value,
    // contrastText: tokens.color.onError.value,
  },
  warning: {
    main: tokens.color.warningMain.value,
    light: tokens.color.warningLight.value,
    dark: tokens.color.warningDark.value,
    // contrastText: tokens.color.onWarning.value,
  },
  info: {
    main: tokens.color.infoMain.value,
    light: tokens.color.infoLight.value,
    dark: tokens.color.infoDark.value,
    // contrastText: tokens.color.onInfo.value,
  },
  success: {
    main: tokens.color.successMain.value,
    light: tokens.color.successLight.value,
    dark: tokens.color.successDark.value,
    // contrastText: tokens.color.onSuccess.value,
  },
  background: {
    default: tokens.color.backgroundDefaultDarkTheme.value,
    paper: tokens.color.backgroundPaperDarkTheme.value,
    page: tokens.color.backgroundPage.value,
  },
  text: {
    primary: tokens.color.textPrimaryDarkTheme.value,
    secondary: tokens.color.textSecondaryDarkTheme.value,
    disabled: tokens.color.textDisabled.value,
  },
  divider: tokens.color.divider.value,
};
