import * as React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { Logout as LogoutIcon } from "@mui/icons-material";
import { signOut } from "next-auth/react";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import InventoryIcon from "@mui/icons-material/Inventory";
import getConfig from "next/config";
import { TbmlTheme } from "@tbml/components/Theme";
import { Container } from "@tbml/components/PageLayout";
import { Spacer } from "@tbml/components/Spacer";
import useScrollSection from "@tbml/components/ScrollSections/useScrollSection";
import {
  BottomAligner,
  LegalSection,
  UserCustomerActions,
  FooterGrid,
  LinkTextSmall,
  LinkWithIcon,
  TransparentDivider,
} from "./styles";

const config = getConfig();
const { authBaseUrl, authApplicationId } = config?.publicRuntimeConfig || {};

export function Footer({
  className,
}: React.HTMLProps<HTMLDivElement>): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const scrollSectionProps = useScrollSection();

  return (
    <BottomAligner {...scrollSectionProps}>
      <TbmlTheme mode="dark">
        <Container>
          <Spacer size="verticalXl" />
          <FooterGrid className={className}>
            <UserCustomerActions>
              <LinkWithIcon href="/issues">
                <InventoryIcon fontSize="inherit" />
                Browse Archive
              </LinkWithIcon>
              <LinkWithIcon
                href={`${authBaseUrl}/account/?client_id=${authApplicationId}`}
              >
                <AccountCircleOutlinedIcon fontSize="inherit" />
                Manage Account
              </LinkWithIcon>
              <LinkWithIcon href="mailto:briefings@unicepta.com?Subject=Account%20Deletion%20Request&Body=I%20would%20like%20to%20request%20the%20deletion%20of%20my%20account.">
                <PersonRemoveIcon fontSize="inherit" />
                Request account deletion
              </LinkWithIcon>
              <LinkWithIcon onClick={() => signOut()} href="/account">
                <LogoutIcon fontSize="inherit" />
                Sign out
              </LinkWithIcon>
            </UserCustomerActions>
            {isMobile && <TransparentDivider orientation="vertical" flexItem />}
            <LegalSection>
              <LinkTextSmall href="#" className="iubenda-cs-preferences-link">
                Privacy Preferences
              </LinkTextSmall>
              <LinkTextSmall
                href="https://www.iubenda.com/privacy-policy/45293579/cookie-policy"
                className="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe "
                title="Cookie policy "
              >
                Cookies
              </LinkTextSmall>
              <LinkTextSmall
                href="https://www.iubenda.com/privacy-policy/45293579"
                className="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe "
                title="Privacy Policy "
              >
                Privacy policy
              </LinkTextSmall>
              <LinkTextSmall
                href="/legal/imprint"
                title="Imprint"
                color="secondary"
              >
                Imprint
              </LinkTextSmall>
            </LegalSection>
          </FooterGrid>
          <Spacer size="verticalXl" />
        </Container>
      </TbmlTheme>
      <input type="hidden" id="logged-in" />
    </BottomAligner>
  );
}
