import * as React from "react";
import { Button, CircularProgress, styled } from "@mui/material";
import { signIn, getProviders } from "next-auth/react";
import { useSession } from "@tbml/hooks/useSession";
import { Spacer } from "../Spacer";
import { Text } from "../Typography";

export type Props = React.PropsWithChildren<{ forceLoading?: boolean }>;

const AlignedContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const CenteredText = styled(Text)`
  text-align: center;
  color: ${({ theme }) => {
    if (!theme || !theme.palette) return false;
    const {
      palette: {
        text: { secondary },
      },
    } = theme;
    return secondary;
  }};
`;

export function Splashscreen({
  children = undefined,
  forceLoading = false,
}: Props): JSX.Element {
  const [session, loading] = useSession();

  const getProvider = React.useCallback(async () => {
    const providers = await getProviders();
    if (!providers) return undefined;

    const [firstProvider, ...otherProviders] = Object.values(providers);
    if (otherProviders.length) return undefined;

    return firstProvider.id as string;
  }, []);

  if (!loading && session === null) {
    return (
      <AlignedContainer>
        <CenteredText paragraph>
          A login is required to access this page.
        </CenteredText>
        <Button
          id="sign-in-button"
          onClick={(e) => {
            e.preventDefault();
            getProvider().then(signIn);
          }}
        >
          Sign in
        </Button>
      </AlignedContainer>
    );
  }

  if (!session || loading || forceLoading)
    return (
      <AlignedContainer>
        <CircularProgress />
        <Spacer size="verticalXl" />
        <CenteredText paragraph>Please wait a second</CenteredText>
        <CenteredText paragraph>
          We&apos;re getting this page ready
        </CenteredText>
      </AlignedContainer>
    );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
