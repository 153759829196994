import * as React from "react";
import fetch from "cross-fetch";
import { GetServerSidePropsContext } from "next";
import { useQuery } from "@tanstack/react-query";
import { Session } from "@tbml/api-interface/session";

export const fetchSession = async (): Promise<null | Session> => {
  const res = await fetch(`${process.env.NEXTAUTH_URL ?? ""}/api/auth/session`);
  const session = await res.json();
  if (Object.keys(session).length) {
    return session;
  }
  return null;
};

export const getSession = async (
  context: GetServerSidePropsContext
): Promise<null | Session> => {
  const res = await fetch(`${process.env.NEXTAUTH_URL}/api/auth/session`, {
    headers: {
      cookie: context.req.headers.cookie ?? "",
    },
  });

  let session;
  try {
    session = await res.json();
  } catch (e) {
    const responseBody = await res.text();
    throw new Error(
      `invalid json response body at ${process.env.NEXTAUTH_URL}/api/auth/session: ${responseBody}`
    );
  }

  if (Object.keys(session).length) {
    return session;
  }
  return null;
};

export const useSession = (): [Session | null | undefined, boolean] => {
  const query = useQuery<Session | null | undefined, Error>({
    queryKey: ["session"],
    queryFn: fetchSession,
  });

  React.useEffect(() => {
    if (query.data?.error === "RefreshAccessTokenError") {
      import("next-auth/react").then(({ signIn }) => {
        signIn(query.data?.provider ?? "fusionauth");
      });
    }
  }, [query.data, query.isPending]);
  return React.useMemo(
    () => [query.data, query.status === "pending"],
    [query.data, query.status]
  );
};
