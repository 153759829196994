import { gql } from "graphql-request";
import { UseStoriesInsideTopicFieldsFragment } from "../useStories/fields";
import { UseNotificationsFieldFragment } from "../useNotifications/fields";

export const UseIssuesFieldsFragment = gql`
  fragment UseIssuesFields on Issue {
    id
    title
    customerId
    createdAt
    refDate
    publicationStatus
    inboxProductIssueId
    notifications {
      ...UseNotificationsField
    }
    additionalCoverage {
      id
      productId
      publishedAt
      updatedAt
      sortSections {
        id
        name
        count
      }
    }
    executiveBriefingTopics {
      id
      title
      subTitle
      stories {
        ...UseStoriesInsideTopicFields
      }
    }
    errors
  }
  ${UseNotificationsFieldFragment}
  ${UseStoriesInsideTopicFieldsFragment}
`;
