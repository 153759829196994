import * as React from "react";

type Updater<T> = (currVal: T) => T;
export type SetterOrUpdater<T> = (
  valOrUpdater: ((currVal: T) => T) | T
) => void;

const isUpdater = <T = unknown>(
  valOrUpdater: ((currVal: T) => T) | T
): valOrUpdater is Updater<T> => typeof valOrUpdater === "function";

export const useLocalStorage = <T = unknown>(
  key: string,
  initialValue?: T
): [T, SetterOrUpdater<T>] => {
  const [storedValue, setStoredValue] = React.useState<T>(() => {
    if (typeof window !== "undefined") {
      try {
        const item = window.localStorage.getItem(key);
        return item ? JSON.parse(item) : initialValue;
      } catch (error) {
        import("@sentry/browser").then(({ captureException }) => {
          captureException(error, {
            extra: { localStorageContent: window.localStorage.getItem(key) },
          });
        });
        return initialValue;
      }
    }

    return initialValue;
  });

  const setValue = React.useCallback<SetterOrUpdater<T>>(
    (valOrUpdater) => {
      setStoredValue((prev) => {
        const newValue = isUpdater(valOrUpdater)
          ? valOrUpdater(prev)
          : valOrUpdater;
        if (typeof window !== "undefined") {
          window.localStorage.setItem(key, JSON.stringify(newValue));
        }
        return newValue;
      });
    },
    [key]
  );
  return React.useMemo(() => [storedValue, setValue], [setValue, storedValue]);
};
