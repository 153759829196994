import * as React from "react";
import { styled } from "@mui/material";
import { CSSTransition } from "react-transition-group";
import { RenderProps } from "@moxy/react-page-swapper";
import { usePageTransition } from "@tbml/hooks/usePageTransition";
import * as animations from "@tbml/hooks/usePageTransition/animations";

export { PageSwapper } from "./PageSwapper";

/* stylelint-disable value-keyword-case */
const Animation = styled("div")<{
  animation:
    | "fade"
    | "slideRight"
    | "slideLeft"
    | "slideTop"
    | "slideBottom"
    | "none"
    | string;
}>`
  min-height: 100vh;
  background: ${({ theme }) => {
    if (!theme || !theme.palette) return false;
    const {
      palette: { background },
    } = theme;
    return background.page;
  }};
  ${({ theme, animation }) => {
    switch (animation) {
      case "fade":
      case "slideTop":
      case "slideBottom":
      case "slideLeft":
      case "slideRight":
        return animations[animation](theme);
      default:
        return animations.none;
    }
  }}
`;
/* stylelint-enable value-keyword-case */

const getTimeout = (animation: string) => {
  switch (animation) {
    case "none":
      return 1;
    default:
      return 350;
  }
};

const getZIndex = (animation: string, inProp?: boolean) => {
  switch (animation) {
    case "slideTop":
    case "slideBottom":
    case "slideLeft":
    case "fade":
      return !inProp ? 0 : 1;
    case "slideRight":
      return !inProp ? 1 : 0;
    default:
      return undefined;
  }
};

export type Props = Omit<RenderProps, "animation">;

export function PageTransition({
  node,
  transitioning = false,
  style,
  in: inProp = false,
  onEntered,
  onExited,
}: Props): JSX.Element {
  const { animationName } = usePageTransition();

  return (
    <CSSTransition
      in={inProp}
      onEntered={onEntered}
      onExited={onExited}
      timeout={getTimeout(animationName)}
    >
      <Animation
        animation={animationName}
        style={{
          ...style,
          zIndex: getZIndex(animationName, inProp),
        }}
      >
        {React.cloneElement(node, { transitioning })}
      </Animation>
    </CSSTransition>
  );
}
