import { styled } from "@mui/material";
import NextImage from "next/image";

export const ClampedImage = styled(NextImage, {
  shouldForwardProp: (prop) =>
    prop !== "allowCropping" &&
    prop !== "autoSize" &&
    prop !== "maxHeight" &&
    prop !== "showOriginalSize",
})<{
  allowCropping?: boolean;
  showOriginalSize?: boolean;
  autoSize?: boolean;
  maxHeight?: number;
}>`
  object-fit: ${({ allowCropping, objectFit }) =>
    objectFit || (allowCropping ? "cover" : "fill")};
  object-position: ${({ allowCropping }) => (allowCropping ? "center" : false)};
  height: ${({ autoSize }) => (autoSize ? "auto" : false)};
  width: ${({ showOriginalSize }) => (showOriginalSize ? false : "100%")};
  max-width: 100%;
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : false)};
`;

export const ImageWrapper = styled("div")<{
  maxHeight: number | string;
  aspectRatio?: string;
}>`
  display: flex;
  justify-content: center;
  position: relative;
  max-height: ${({ maxHeight }) => maxHeight}px;
  max-width: 100%;
  width: 100%;
  aspect-ratio: ${({ aspectRatio }) => aspectRatio ?? "auto"};
`;
