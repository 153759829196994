import { gql } from "graphql-request";

export const UseArticlesFieldsFragment = gql`
  fragment UseArticlesFieldsFragment on Article {
    id
    headline
    customHeadline
    author
    articleSortSectionRank
    publishedAt
    deltaContent
    deltaSnippet
    language
    inboxProductIssueId
    clippingLayoutUrl
    deeplink
    mediaId
    mediaName
    mediaForm
    mediaSubForm
    mediaSubIssue
    pages
    sortSection {
      id
      name
    }
    abstracts {
      isoCode
      deltaContent
    }
    images {
      id
      url
      caption
      selected
    }
    broadcastData {
      id
      publicUrl
      duration
    }
  }
`;
