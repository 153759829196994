import React, { CSSProperties } from "react";
import { styled } from "@mui/material";

export const PAGE_LAYOUT_MAX_WIDTH_NUMBER = 1340;
export const PAGE_LAYOUT_MAX_WIDTH = `${PAGE_LAYOUT_MAX_WIDTH_NUMBER}px`;
export const PAGE_LAYOUT_MIN_WIDTH = "320px";
export const PAGE_LAYOUT_PADDING = "4%";
export const PAGE_LAYOUT_SIDE_LINE_WIDTH = "3px";

const StyledPageLayout = styled("div")`
  background-color: ${({ theme }) => {
    if (!theme || !theme.palette) return false;
    const {
      palette: { background },
    } = theme;
    return background.page;
  }};
  min-width: ${PAGE_LAYOUT_MIN_WIDTH};
  max-width: ${PAGE_LAYOUT_MAX_WIDTH};
  margin: 0 auto;
  min-height: 100%;
`;

export const Container = styled("div")<{
  addBorder?: "left" | "right";
  position?: CSSProperties["position"];
}>`
  background-color: ${({ theme }) => theme.palette.background.default};
  border-left: ${({ addBorder, theme }) => {
    if (!addBorder) return null;
    return `${PAGE_LAYOUT_SIDE_LINE_WIDTH} solid ${
      addBorder === "left" ? theme.palette.primary.main : "transparent"
    }`;
  }};
  border-right: ${({ addBorder, theme }) => {
    if (!addBorder) return null;
    return `${PAGE_LAYOUT_SIDE_LINE_WIDTH} solid ${
      addBorder === "right" ? theme.palette.primary.main : "transparent"
    }`;
  }};
  padding: ${({ addBorder }) =>
    addBorder
      ? `0 calc(${PAGE_LAYOUT_PADDING} - ${PAGE_LAYOUT_SIDE_LINE_WIDTH})`
      : `0 ${PAGE_LAYOUT_PADDING}`};
  position: ${({ position }) => position || "relative"};
  min-height: 100%;
`;

export function PageLayout({
  children = undefined,
  ...props
}: React.PropsWithChildren<unknown>): JSX.Element {
  return <StyledPageLayout {...props}>{children}</StyledPageLayout>;
}
