import { Divider, styled } from "@mui/material";
import tokens from "@tbml/tokens";
import { Link } from "@tbml/components/Link";

export const FooterGrid = styled("div")`
  display: flex;
  background: ${({ theme }) => {
    if (!theme || !theme.palette) return false;
    const {
      palette: { background },
    } = theme;
    return background.default;
  }};
  justify-content: space-between;
  width: 100%;
  color: ${tokens.color.textSecondaryDarkTheme.value};
`;

export const LinkTextSmall = styled(Link)`
  display: flex;
  font-size: ${tokens.font.shortTextSmall.fontSize.value};
`;

export const LinkWithIcon = styled(LinkTextSmall)`
  display: flex;
  align-items: center;
  column-gap: ${tokens.spacing.paddingS.value};
`;

export const UserCustomerActions = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${tokens.spacing.paddingS.value};
`;

export const LegalSection = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${tokens.spacing.verticalXl.value};
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    flex-direction: column;
    align-items: flex-start;
    gap: ${tokens.spacing.paddingS.value};
  }
`;

export const BottomAligner = styled("div")`
  margin-top: auto;
`;
export const TransparentDivider = styled(Divider)`
  background: ${tokens.color.dividerDarkTheme.value};
`;
