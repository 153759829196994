import React, { BaseSyntheticEvent } from "react";
import { Box, Dialog, IconButton, Stack } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { Asset } from "@tbml/api-interface/graphql";
import { Image } from "../Image";

function shouldUseImg(
  image: Asset | undefined,
  src: string
): image is undefined {
  return (
    image === undefined &&
    (src.startsWith("data:image") || src.startsWith("http"))
  );
}

type Props = {
  image?: Asset;
  isOpen: boolean;
  images?: string[];
  setIsOpen: (isOpen: boolean) => void;
  src: string;
  width?: number;
  height?: number;
};

export function ImageModal({
  image = undefined,
  isOpen,
  setIsOpen,
  images = [],
  src,
  width = undefined,
  height = undefined,
}: Props): JSX.Element {
  const handleClose = () => setIsOpen(false);

  const renderImage = (imageSrc: string) => {
    if (shouldUseImg(image, imageSrc)) {
      return (
        // eslint-disable-next-line @next/next/no-img-element
        <img
          src={imageSrc}
          alt="Overlay"
          style={{ width: "100%", height: "auto" }}
        />
      );
    }
    return (
      <Image
        src={imageSrc}
        alt="Image overlay"
        style={{ maxWidth: "100%", maxHeight: "100%" }}
        objectFit="contain"
        width={image?.mediaWidth}
        height={image?.mediaHeight}
        autoSize
      />
    );
  };

  return (
    <Dialog
      open={isOpen}
      onClose={(event: BaseSyntheticEvent) => {
        event.preventDefault();
        handleClose();
      }}
      aria-labelledby="image-modal"
      maxWidth={false}
    >
      <Box
        onClick={(event) => {
          event.preventDefault();
          handleClose();
        }}
        sx={{
          boxShadow: 24,
          p: 1,
          width: width ? `${width}px` : "auto",
          height: height ? `${height}px` : "auto",
        }}
      >
        <Stack>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {images && images.length > 0
            ? images.map((imageSrc) => (
                <Box key={`${imageSrc}`} mb={2}>
                  {renderImage(imageSrc)}
                </Box>
              ))
            : src && renderImage(src)}
        </Stack>
      </Box>
    </Dialog>
  );
}
