import * as React from "react";
import NextLink, { LinkProps } from "next/link";
import {
  styled,
  LinkProps as MuiLinkProps,
  Link as MaterialUiLink,
} from "@mui/material";
import {
  AnimationName,
  defaultPageTransitionAnimationName,
  usePageTransition,
} from "@tbml/hooks/usePageTransition";

const StyledLink = styled(MaterialUiLink)<{ "data-animation": string }>`
  color: ${({ theme }) => {
    if (!theme || !theme.palette) return false;
    const {
      palette: {
        text: { secondary },
      },
    } = theme;
    return secondary;
  }};

  &:hover {
    color: ${({ theme }) => {
      if (!theme || !theme.palette) return false;
      const {
        palette: {
          text: { primary },
        },
      } = theme;
      return primary;
    }};
  }
`;

export type AnimatedLinkProps<T extends React.ElementType = React.ElementType> =
  LinkProps &
    MuiLinkProps &
    React.ComponentPropsWithoutRef<T> & {
      animation?: AnimationName;
      styledLinkAs?: T;
    };

export function Link({
  animation = defaultPageTransitionAnimationName,
  children = undefined,
  href,
  as,
  styledLinkAs = undefined,
  target,
  prefetch,
  onMouseEnter = () => {},
  onFocus = () => {},
  onClick = () => {},
  ...props
}: React.PropsWithChildren<AnimatedLinkProps>): JSX.Element {
  const { animationName: currentAnimationName, setAnimationName } =
    usePageTransition();

  return (
    <NextLink
      as={as}
      href={href}
      target={target}
      prefetch={prefetch}
      passHref
      scroll={false}
      className={props.className}
    >
      <StyledLink
        {...props}
        {...(styledLinkAs ? { as: styledLinkAs } : {})}
        target={target}
        data-animation={animation}
        onMouseEnter={(e) => {
          if (animation !== currentAnimationName) setAnimationName(animation);
          onMouseEnter(e);
        }}
        onFocus={(e) => {
          if (animation !== currentAnimationName) setAnimationName(animation);
          onFocus(e);
        }}
        onClick={(e) => {
          if (animation !== currentAnimationName) setAnimationName(animation);
          onClick(e);
        }}
      >
        {children}
      </StyledLink>
    </NextLink>
  );
}
