import { useCallback, useEffect, useId, useRef } from "react";
import {
  atom,
  SetterOrUpdater,
  useSetRecoilState,
  useRecoilState,
} from "@tbml/shared-dependencies/recoil";

export type ScrollSection = {
  elementId: string;
  y: number;
  yEnd: number;
};

const scrollSectionsAtom = atom<ScrollSection[]>({
  key: "scrollSections",
  default: [],
});
const currentScrollSectionIdAtom = atom<string | null>({
  key: "currentScrollSectionId",
  default: null,
});

type UseScrollSections = () => [
  ScrollSection[],
  SetterOrUpdater<ScrollSection[]>
];
export const useScrollSections: UseScrollSections = () =>
  useRecoilState(scrollSectionsAtom);

type UseCurrentScrollSectionId = () => [
  string | null,
  SetterOrUpdater<string | null>
];
export const useCurrentScrollSectionId: UseCurrentScrollSectionId = () =>
  useRecoilState(currentScrollSectionIdAtom);

const getDimensions = (elementId: string, elementArg?: HTMLElement) => {
  const element = elementArg || document.getElementById(elementId);
  if (!element) return null;
  return {
    y: element.offsetTop,
    yEnd: element.offsetTop + element.offsetHeight,
  };
};
const hasDimensions = (
  section: ScrollSection | Partial<ScrollSection>
): section is ScrollSection =>
  typeof section.y === "number" && typeof section.yEnd === "number";

type UseScrollSection = () => {
  id: string;
  ref: (element: HTMLDivElement) => void;
};

const getResizeObserver = (callback: (element: HTMLDivElement) => void) =>
  new window.ResizeObserver((entries: ResizeObserverEntry[]) => {
    for (const entry of entries) {
      callback(entry.target as HTMLDivElement);
    }
  });

const useScrollSection: UseScrollSection = () => {
  const setScrollSections = useSetRecoilState(scrollSectionsAtom);
  const elementId = useId();

  const updateScrollSections = useCallback(
    (element: HTMLDivElement) => {
      if (element) {
        const newScrollSection = {
          elementId,
          ...getDimensions(elementId, element),
        };
        setScrollSections((prev) =>
          prev
            .filter((section) => section.elementId !== elementId)
            .map((section) => ({
              elementId: section.elementId,
              ...getDimensions(section.elementId),
            }))
            .concat(newScrollSection)
            .filter(hasDimensions)
            .sort((a, b) => a.y - b.y)
        );
      }
    },
    [elementId, setScrollSections]
  );

  const resizeObserverRef = useRef(getResizeObserver(updateScrollSections));

  const ref = useCallback((element?: HTMLDivElement) => {
    if (element) {
      resizeObserverRef.current.observe(element);
    }
  }, []);

  useEffect(
    () => () => {
      resizeObserverRef.current.disconnect();

      setScrollSections((prev) =>
        prev.filter((section) => section.elementId !== elementId)
      );
    },
    [elementId, setScrollSections]
  );

  return {
    id: elementId,
    ref,
  };
};

export default useScrollSection;
