import {
  atom,
  SetterOrUpdater,
  useRecoilState,
} from "@tbml/shared-dependencies/recoil";
import * as animations from "./animations";

export type AnimationName = keyof typeof animations;

export const defaultPageTransitionAnimationName: AnimationName = "fade";

const pageTransitionAnimationNameAtom = atom<AnimationName>({
  key: "pageTransitionAnimationName",
  default: defaultPageTransitionAnimationName,
});

const pageTransitionAnimationLocked = atom<boolean>({
  key: "pageTransitionAnimationLock",
  default: false,
});

export const usePageTransition = (): {
  animationName: AnimationName;
  setAnimationName: SetterOrUpdater<AnimationName>;
  lock: boolean;
  setLock: SetterOrUpdater<boolean>;
} => {
  const [lock, setLock] = useRecoilState(pageTransitionAnimationLocked);
  const [animationName, setValue] = useRecoilState(
    pageTransitionAnimationNameAtom
  );

  return {
    animationName,
    setAnimationName: (newAnimation) => {
      if (lock) return;
      setValue(newAnimation);
    },
    lock,
    setLock,
  };
};
