export const CategoryConsentId = {
  Necessary: 1,
  Functionality: 2,
  Experience: 3,
  Measurement: 4,
  Marketing: 5,
} as const;

type CategoryConsentKeys =
  (typeof CategoryConsentId)[keyof typeof CategoryConsentId];

type Iubenda = {
  cs: {
    consent: {
      purposes: { [key in CategoryConsentKeys]: boolean };
    };
    api: {
      openPreferences: () => void;
    };
  };
};

declare global {
  interface Window {
    _iub: Iubenda;
  }
}

export const iubendaId = {
  siteId: 2722775,
  cookiePolicyId: 45293579,
};

export const iubendaConfig = `{
  askConsentAtCookiePolicyUpdate: true,
  ccpaAcknowledgeOnDisplay: true,
  consentOnContinuedBrowsing: false,
  countryDetection: true,
  enableCcpa: true,
  invalidateConsentWithoutLog: true,
  enableRemoteConsent: true,
  googleConsentMode: true,
  lang: "en-GB",
  perPurposeConsent: true,
  siteId: ${iubendaId.siteId},
  cookiePolicyId: ${iubendaId.cookiePolicyId},
  callback: {
    onConsentRead: () => {
      window.prevPurpose = window._iub.cs.consent.purposes;
    },
    onPreferenceExpressed: ({ purposes }) => {
      if (purposes["3"] && window.prevPurpose?.["3"] !== undefined && !window.prevPurpose["3"]) {
        location.reload();
      }
    }
  },
  banner: {
    acceptButtonCaptionColor: "#FFFFFF",
    acceptButtonColor: "#0073CE",
    acceptButtonDisplay: true,
    backgroundColor: "#FFFFFF",
    backgroundOverlay: true,
    brandBackgroundColor: "#FFFFFF",
    brandTextColor: "#000000",
    closeButtonDisplay: false,
    customizeButtonCaptionColor: "#4D4D4D",
    customizeButtonColor: "#DADADA",
    customizeButtonDisplay: true,
    explicitWithdrawal: true,
    listPurposes: true,
    logo: "https://briefings.unicepta.app/images/unicepta_logo.svg",
    position: "float-top-center",
    rejectButtonCaptionColor: "#FFFFFF",
    rejectButtonColor: "#0073CE",
    rejectButtonDisplay: true,
    textColor: "#000000",
  },
}`;
