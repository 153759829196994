import * as React from "react";
import fetch from "cross-fetch";
import { GraphQLClient } from "graphql-request";
import merge from "lodash/merge";
import { useSession } from "../useSession";

export type UseApiResult = {
  url: string;
  fetch: typeof fetch;
  token?: string;
  provider: "fusionauth";
  client: GraphQLClient;
};

export const UseApiContext = React.createContext<
  Omit<UseApiResult, "fetch" | "token" | "client"> & { clientKey?: string }
>({
  url: "",
  provider: "fusionauth",
});

const createNestedObject = (
  current: Record<string, unknown>,
  parts: string[],
  value: unknown
): Record<string, unknown> => {
  if (parts.length === 0) return current;
  const [top, ...rest] = parts;
  if (parts.length === 1) {
    return {
      ...current,
      [top]: value instanceof File ? { name: value.name } : value,
    };
  }

  return {
    ...current,
    [top]: createNestedObject({}, rest, value),
  };
};

export const useApi = (): UseApiResult => {
  const context = React.useContext(UseApiContext);
  const clientKey = context.clientKey ?? "static";
  const [session, loading] = useSession();
  const extendedFetch: typeof fetch | null = React.useMemo(
    () =>
      loading || !session?.accessToken
        ? (...params) => {
            // eslint-disable-next-line no-console
            console.warn(
              "Request was made without authentication.\nWait for the session to be available before making additional requests."
            );
            return fetch(...params);
          }
        : (info, init) =>
            fetch(info, {
              ...init,
              headers: {
                ...init?.headers,
                Authorization: `bearer ${session?.accessToken}`,
              },
            }),
    [loading, session?.accessToken]
  );
  const client = React.useMemo(
    () =>
      new GraphQLClient(`${context.url}/graph`, {
        headers: {
          Authorization: `bearer ${session?.accessToken}`,
          "Apollo-Require-Preflight": "true",
        },
        fetch,
        requestMiddleware: (request) => {
          if (process.env.NODE_ENV !== "test") return request;
          if (!(request.body instanceof FormData)) return request;

          // ****************************************
          // This code converts form data into json.
          // Otherwise, MSW won't be able to match mocks to the request.
          // ****************************************
          const map: Record<string, string[]> = JSON.parse(
            request.body.get("map") as string
          );
          const { variables } = Object.entries(map).reduce(
            (acc, [valueIndex, [name]]) => {
              if (!(request.body instanceof FormData)) return acc;

              const value = request.body.get(valueIndex);
              return createNestedObject(
                { variables: {} },
                name.split("."),
                value
              ) as { variables: Record<string, unknown> };
            },
            { variables: {} }
          );

          const operations = JSON.parse(
            request.body.get("operations") as string
          );

          return {
            ...request,
            headers: {
              ...request.headers,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...operations,
              variables: merge(operations.variables, variables),
            }),
          };
        },
        responseMiddleware: (response) => {
          if (
            response instanceof Error &&
            response.message.includes("GraphQL Error (Code: 401)")
          ) {
            import("next-auth/react").then(({ signOut }) => signOut());
          }

          return response;
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [context.url, session?.accessToken, clientKey]
  );

  return {
    ...context,
    fetch: extendedFetch,
    token: session?.accessToken,
    client,
  };
};
