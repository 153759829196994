import { useCallback, useEffect, useMemo } from "react";
import { throttle } from "lodash";
import { usePathname } from "next/navigation";
import { ScrollCacheController } from "./scrollCacheController";

const SCROLL_STORAGE_KEY = "@@scroll-position";

function getStorageKey(path: string) {
  return `${SCROLL_STORAGE_KEY}${path}`;
}

/**
 * This saves the most recent scroll position to session storage in order to be able to
 * restore the scroll position after navigating. Additionally it maintains an in memory cache (ScrollCacheController) of scroll events positions in order to prevent
 * single scroll events (that can be triggered by layout changes) from influencing the saved scroll position.
 */
export function useScrollRestore(): { setScroll: () => void } {
  const pathname = usePathname();
  const updater = useCallback((key: string, value: number) => {
    sessionStorage.setItem(key, `${value}`);
  }, []);
  const cacheController = useMemo(
    () => new ScrollCacheController(updater),
    [updater]
  );
  useEffect(() => {
    function handleScroll() {
      const nextValue = window.scrollY;
      if (!pathname) {
        return;
      }
      cacheController.updateCache(getStorageKey(pathname), nextValue);
    }
    const throttledHandleScroll = throttle(handleScroll, 50);
    document.addEventListener("scroll", throttledHandleScroll);

    return () => document.removeEventListener("scroll", throttledHandleScroll);
  }, [pathname, cacheController]);

  return {
    setScroll: () => {
      if (!pathname) {
        return;
      }
      const scrollValue =
        sessionStorage.getItem(getStorageKey(pathname)) ?? "0";
      // restore scroll after the possible layout shift
      setTimeout(async () => {
        window.scrollTo(0, parseInt(scrollValue, 10));
      }, 100);
    },
  };
}
