import { ClientError } from "graphql-request";

export const DAYS_UNTIL_ARCHIVE = 28;

export const isClientError = (
  error: Error | ClientError | null
): error is ClientError =>
  !!error &&
  "response" in error &&
  "data" in error.response &&
  Array.isArray(error.response.errors) &&
  "request" in error &&
  error.response.status === 200;

export const isLicenseError = (
  error: Error | ClientError | null
): error is ClientError =>
  isClientError(error) && error?.message.startsWith("License has expired");

export const isLicenseFreeError = (
  error: Error | ClientError | null
): error is ClientError =>
  isClientError(error) &&
  error.message.startsWith("The license state of this Issue is 'license-free'");
