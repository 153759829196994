import { useRouter } from "@tbml/shared-dependencies/router";
import { useIsInternalUser } from "@tbml/hooks/useIsInternalUser";

export const useShouldShowDebugPanel = (): {
  isInternalUser: boolean | undefined;
  isLoading: boolean;
  shouldShowDebugPanel: boolean;
} => {
  const { query } = useRouter();
  const { data: isInternalUser, isLoading } = useIsInternalUser();

  const isExternalUser = !isLoading && !isInternalUser;
  return {
    isInternalUser,
    isLoading,
    shouldShowDebugPanel: !query.formFactor && !isExternalUser,
  };
};
