import { Skeleton as MuiSkeleton, styled } from "@mui/material";

export const LimitedSkeleton = styled(MuiSkeleton, {
  shouldForwardProp: (propName) =>
    propName !== "maxWidth" && propName !== "maxHeight",
})<{
  maxWidth?: string;
  maxHeight?: string;
}>`
  max-width: ${({ maxWidth }) => maxWidth ?? "unset"};
  max-height: ${({ maxHeight }) => maxHeight ?? "unset"};
`;
