import * as React from "react";
import { Session } from "@tbml/api-interface/session";
import { useLocalStorage } from "../useLocalStorage";
import { useSession } from "../useSession";

type Email = string;
type CustomerId = string;
export type SelectedCustomers = {
  [key: Email]: CustomerId;
};

export type CurrentCustomerIdContextValue = [
  currentCustomerId: string | null,
  setCurrentCustomerId: (newCustomerId: string | null) => void
];

export const SELECTED_CUSTOMER_KEY = "selected-customer-ids";

export const CurrentCustomerIdContext = React.createContext<
  [
    currentCustomerId: string | null,
    setCurrentCustomerId: (newCustomerId: string | null) => void
  ]
>([null, () => {}]);

export const useCurrentCustomerId = (): [
  currentCustomerId: string | null,
  setCurrentCustomerId: (newCustomerId: string | null) => void
] => React.useContext(CurrentCustomerIdContext);

const setCustomerIdForEmail = (
  currentCustomerIds: SelectedCustomers,
  customerId: CustomerId | null | undefined,
  session: Session | null | undefined
): SelectedCustomers => {
  if (!session || !customerId) {
    return currentCustomerIds;
  }

  const {
    user: { email },
  } = session;

  return {
    ...currentCustomerIds,
    [email]: customerId,
  };
};

export function CurrentCustomerIdProvider({
  value = undefined,
  children = undefined,
}: React.PropsWithChildren<{
  value?: CustomerId | null;
}>): JSX.Element {
  const [session] = useSession();
  const [fallbackCustomerId] = React.useState<typeof value>(value);

  const [selectedCustomerIds, setSelectedCurrentCustomerIds] =
    useLocalStorage<SelectedCustomers>(
      SELECTED_CUSTOMER_KEY,
      setCustomerIdForEmail({}, value, session)
    );

  const mailExists =
    session?.user.email && session.user.email in selectedCustomerIds;

  React.useEffect(() => {
    if (
      value !== undefined &&
      mailExists &&
      value !== selectedCustomerIds[session.user.email]
    )
      setSelectedCurrentCustomerIds((currentCustomer) =>
        setCustomerIdForEmail(currentCustomer, value, session)
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSelectedCurrentCustomerIds, value, session?.user.email]);

  const currentCustomerIdContextValue: CurrentCustomerIdContextValue =
    React.useMemo(
      () => [
        mailExists
          ? selectedCustomerIds[session?.user.email]
          : fallbackCustomerId ?? null,
        (val) =>
          setSelectedCurrentCustomerIds((currentCustomerIds) =>
            setCustomerIdForEmail(currentCustomerIds, val, session)
          ),
      ],
      [
        mailExists,
        selectedCustomerIds,
        session,
        fallbackCustomerId,
        setSelectedCurrentCustomerIds,
      ]
    );

  return (
    <CurrentCustomerIdContext.Provider value={currentCustomerIdContextValue}>
      {children}
    </CurrentCustomerIdContext.Provider>
  );
}
