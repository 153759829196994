import React from "react";
import {
  atom,
  useRecoilState,
  useSetRecoilState,
} from "@tbml/shared-dependencies/recoil";
import { ImageModal } from "./index";

const imageModalSrcAtom = atom<string | null>({
  key: "imageModalSrc",
  default: null,
});

export const useSetImageModalSrc = (): ((value: string | null) => void) =>
  useSetRecoilState(imageModalSrcAtom);
const useImageModalSrcState = () => useRecoilState(imageModalSrcAtom);

export function ImageModalContainer(): JSX.Element | null {
  const [src, setSrc] = useImageModalSrcState();
  const handleSetIsOpen = (newValue: boolean) => {
    if (newValue === false) {
      setSrc(null);
    }
  };
  if (!src) return null;
  return <ImageModal isOpen setIsOpen={handleSetIsOpen} src={src} />;
}
