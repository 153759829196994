import * as React from "react";
import { styled, Typography, TypographyProps } from "@mui/material";

export function H1(props: TypographyProps): JSX.Element {
  return <Typography color="textPrimary" {...props} variant="h1" />;
}

export function H2(props: TypographyProps): JSX.Element {
  return <Typography color="textPrimary" {...props} variant="h2" />;
}

export function H3(props: TypographyProps): JSX.Element {
  return <Typography color="textPrimary" {...props} variant="h3" />;
}

export function H4(props: TypographyProps): JSX.Element {
  return <Typography color="textPrimary" {...props} variant="h4" />;
}

export function H5(props: TypographyProps): JSX.Element {
  return <Typography color="textPrimary" {...props} variant="h5" />;
}

export function H6(props: TypographyProps): JSX.Element {
  return <Typography color="textPrimary" {...props} variant="h6" />;
}

export function Caption(props: TypographyProps): JSX.Element {
  return <Typography color="textPrimary" {...props} variant="caption" />;
}

export const TextLarge: React.FC<TypographyProps> = styled((props) => (
  <Typography
    color="textPrimary"
    {...props}
    variant="shortTextLarge"
    paragraph
  />
))`
  margin: 0;
`;
TextLarge.displayName = "TextLarge";

export const Text: React.FC<TypographyProps> = styled((props) => (
  <Typography
    color="textPrimary"
    paragraph
    {...props}
    variant="shortTextDefault"
  />
))`
  margin: 0;
`;
Text.displayName = "Text";

export const LongText: React.FC<TypographyProps> = styled((props) => (
  <Typography
    color="textPrimary"
    {...props}
    variant="longTextDefault"
    paragraph
  />
))`
  margin: 0;
`;
LongText.displayName = "LongText";

export const TextSmall: React.FC<TypographyProps> = styled((props) => (
  <Typography {...props} variant="shortTextSmall" />
))`
  margin: 0;
`;
TextSmall.displayName = "TextSmall";

export const LongTextSmall: React.FC<TypographyProps> = styled((props) => (
  <Typography {...props} variant="longTextSmall" paragraph />
))`
  margin: 0;
`;
LongTextSmall.displayName = "LongTextSmall";

export const TextXsmall: React.FC<TypographyProps> = styled((props) => (
  <Typography {...props} variant="shortTextXsmall" />
))`
  margin: 0;
`;
TextXsmall.displayName = "TextXsmall";
