import * as React from "react";
import * as Sentry from "@sentry/react";
import type { AppProps } from "next/app";
import Head from "next/head";
import getConfig from "next/config";
import Script from "next/script";
import { styled } from "@mui/material";
import {
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { UseApiContext, UseApiResult } from "@tbml/hooks/useApi";
import { RecoilRoot } from "@tbml/shared-dependencies/recoil";
import {
  BrandColorFetcher,
  TbmlStyling,
  TbmlTheme,
} from "@tbml/components/Theme";
import { PageLayout } from "@tbml/components/PageLayout";
import { SnackbarProvider } from "@tbml/shared-dependencies/notistack";
import { CurrentCustomerIdProvider } from "@tbml/hooks/useCurrentCustomerId";
import { Splashscreen } from "@tbml/components/Splashscreen";
import { ScrollSections } from "@tbml/components/ScrollSections";
import { ImageModalContainer } from "@tbml/components/ImageModal/ImageModalContainer";
import { createEmotionCache } from "../lib/createEmotionCache";
import { DebugPanel } from "../components/DebugPanel";
import { PageSwapper, PageTransition } from "../components/PageTransition";
import { Footer } from "../components/Footer";
import { iubendaConfig } from "../lib/iubenda";

const {
  publicRuntimeConfig: { apiUrl },
} = getConfig();

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

// eslint-disable-next-line no-restricted-globals
if (typeof history !== "undefined") {
  // eslint-disable-next-line no-restricted-globals
  history.scrollRestoration = "manual";
}

const FlexColumn = styled("div")`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

function Hydrate({
  dehydratedState,
}: {
  dehydratedState: unknown;
}): JSX.Element | null {
  const [isClientRender, setIsClientRender] = React.useState(false);

  // make sure first client render matches the server render
  React.useEffect(() => {
    if (isClientRender) return;
    setIsClientRender(true);
  }, [isClientRender]);

  if (!isClientRender) {
    return null;
  }
  return <HydrationBoundary state={dehydratedState} />;
}

function Application({
  Component,
  pageProps,
  router,
}: AppProps<{ dehydratedState: unknown }>): JSX.Element {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: { queries: { staleTime: 10000 } },
      })
  );

  const useApiContextValue: Omit<UseApiResult, "fetch" | "token" | "client"> =
    React.useMemo(() => ({ url: apiUrl, provider: "fusionauth" }), []);

  const {
    query: { customerId },
  } = router;

  const routeKey = React.useMemo(
    () => `${router.route}.${JSON.stringify(router.query)}`,
    [router.route, router.query]
  );

  const [mode, setMode] = React.useState<"light" | "dark">("light");

  return (
    <>
      <Head>
        <title>Executive News Briefing by UNICEPTA</title>
        <link
          rel="apple-touch-icon"
          sizes="1024x1024"
          href="/favicons/apple-touch-icon.png"
        />
        <link
          rel="shortcut icon"
          sizes="1024x1024"
          href="/favicons/icon-android.png"
        />
        <link
          rel="icon"
          type="image/x-icon"
          sizes="144x144"
          href="/favicons/favicon-144x144.ico"
        />
        <link
          rel="icon"
          type="image/x-icon"
          sizes="96x96"
          href="/favicons/favicon-96x96.ico"
        />
        <link
          rel="icon"
          type="image/x-icon"
          sizes="48x48"
          href="/favicons/favicon-48x48.ico"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Executive News Briefing by UNICEPTA"
        />
        <meta
          property="og:description"
          content="Your precise insight into global media buzz: UNICEPTA's Executive News Briefing provides you quick access to all essential information and stories of the day."
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:image" content="/images/thumbnail.png" />
        <meta property="og:image" content="/images/thumbnail.png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link rel="dns-prefetch" href="https://fonts.googleapis.com/" />
        <link rel="dns-prefetch" href={apiUrl} />
      </Head>
      <Script
        id="iub_config"
        dangerouslySetInnerHTML={{
          __html: `
        var _iub = _iub || [];
        _iub.csConfiguration = ${iubendaConfig}
        `,
        }}
      />
      <Script
        type="text/javascript"
        src="/iubenda.js"
        strategy="afterInteractive"
      />
      <Script
        type="text/javascript"
        src="https://cdn.iubenda.com/cs/ccpa/stub.js"
        strategy="lazyOnload"
      />
      <Script
        type="text/javascript"
        src="https://cdn.iubenda.com/cs/iubenda_cs.js"
        charSet="UTF-8"
        strategy="lazyOnload"
      />
      <TbmlStyling cache={clientSideEmotionCache} mode={mode}>
        <PageLayout>
          <UseApiContext.Provider value={useApiContextValue}>
            <QueryClientProvider client={queryClient}>
              <Hydrate dehydratedState={pageProps.dehydratedState} />
              <RecoilRoot>
                <SnackbarProvider maxSnack={3}>
                  <CurrentCustomerIdProvider
                    {...(typeof customerId === "string"
                      ? { value: customerId }
                      : {})}
                  >
                    <BrandColorFetcher>
                      <TbmlTheme mode={mode}>
                        <Splashscreen>
                          <FlexColumn>
                            <PageSwapper
                              nodeKey={routeKey}
                              node={<Component {...pageProps} />}
                              animation="none"
                            >
                              {(props) => <PageTransition {...props} />}
                            </PageSwapper>
                            <Footer />
                            <ScrollSections />
                          </FlexColumn>
                          <ImageModalContainer />
                        </Splashscreen>
                      </TbmlTheme>
                    </BrandColorFetcher>
                    <DebugPanel mode={mode} setMode={setMode} />
                  </CurrentCustomerIdProvider>
                </SnackbarProvider>
              </RecoilRoot>
            </QueryClientProvider>
          </UseApiContext.Provider>
        </PageLayout>
      </TbmlStyling>
    </>
  );
}

// noinspection JSUnusedGlobalSymbols
export default Sentry.withProfiler(Application);
