import React, { useCallback, useEffect, useState } from "react";
import { ImageLoader, ImageProps } from "next/image";
import getConfig from "next/config";
import { useSkeleton } from "@tbml/shared-dependencies/react-skeletons";
import { Skeleton } from "../Skeleton";
import { ClampedImage, ImageWrapper } from "./styles";

const isTestEnvironment =
  process.env.IS_STORYBOOK || process.env.NODE_ENV === "test";

export const DEFAULT_IMAGE_WIDTH = 900;
export const DEFAULT_IMAGE_HEIGHT = 736;

const imgixBucketUrl =
  getConfig()?.publicRuntimeConfig?.imgixBucketUrl ||
  "https://unicepta.imgix.net";

export type Props = {
  src?: string | null;
  width?: number;
  height?: number;
  maxHeight?: number;
  isLoading?: boolean;
  allowCropping?: boolean;
  showOriginalSize?: boolean;
  autoSize?: boolean;
  aspectRatio?: string;
  objectFit?: "fill" | "contain" | "cover" | "none" | "scale-down";
} & Omit<ImageProps, "src" | "objectFit">;

const defaultProps: {
  src: NonNullable<Props["src"]> | null;
  isLoading: NonNullable<Props["isLoading"]>;
  fill: NonNullable<Props["fill"]>;
} = {
  src: null,
  isLoading: false,
  fill: false,
};

const normalizeSrc = (src: string) => (src[0] === "/" ? src.slice(1) : src);

const imgixLoader: ImageLoader = ({ src, width, quality }) => {
  // Demo: https://static.imgix.net/daisy.png?auto=format&fit=max&w=300

  const url = new URL(`${imgixBucketUrl}/${normalizeSrc(src)}`);

  if (url.href.endsWith(".gif")) return url.href; // no transformation for gifs
  const params = url.searchParams;
  // auto params can be combined with comma separation, or reiteration
  params.set("auto", params.getAll("auto").join(",") || "format");
  params.set("fit", params.get("fit") || "max");
  params.set("w", params.get("w") || width.toString());
  if (quality) {
    params.set("q", quality.toString());
  }
  return url.href;
};

export function Image({
  src: srcProp = defaultProps.src,
  width = DEFAULT_IMAGE_WIDTH,
  height = DEFAULT_IMAGE_HEIGHT,
  maxHeight = DEFAULT_IMAGE_HEIGHT,
  isLoading = defaultProps.isLoading,
  fill = defaultProps.fill,
  alt,
  priority,
  allowCropping,
  showOriginalSize,
  autoSize,
  onLoadingComplete,
  aspectRatio,
  onClick,
  objectFit,
  ...props
}: Props): JSX.Element {
  const [src, setSrc] = useState<string | null | Error>(srcProp);

  useEffect(() => {
    if (src === srcProp) return;
    setSrc(srcProp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [srcProp]);

  const ImageSkeleton = useCallback(
    () => (
      <ImageWrapper maxHeight={maxHeight} aspectRatio={aspectRatio}>
        <Skeleton
          width="100%"
          height={height}
          maxWidth={`${DEFAULT_IMAGE_WIDTH}px`}
        />
      </ImageWrapper>
    ),
    [aspectRatio, height, maxHeight]
  );

  const { withSkeleton } = useSkeleton({
    isLoading,
    Skeleton: ImageSkeleton,
  });

  return withSkeleton(
    <ImageWrapper aspectRatio={aspectRatio} maxHeight={maxHeight} {...props}>
      {typeof src === "string" && (
        <ClampedImage
          fill={fill}
          src={src}
          onErrorCapture={() => {
            setSrc(new Error("Unable to load image"));
          }}
          loader={imgixLoader}
          alt={alt}
          width={width}
          height={height}
          maxHeight={maxHeight}
          priority={priority}
          allowCropping={allowCropping}
          showOriginalSize={showOriginalSize}
          autoSize={autoSize}
          onLoadingComplete={onLoadingComplete}
          onClick={onClick}
          objectFit={objectFit}
          {...(isTestEnvironment ? { unoptimized: true } : {})}
        />
      )}
      {src === null && <Skeleton width={width} height={height} />}
    </ImageWrapper>
  );
}

Image.defaultProps = defaultProps;
