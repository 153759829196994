type UpdaterFn = (key: string, value: number) => void;

export class ScrollCacheController {
  /**
   * In memory cache for scrolling history.
   */
  private scrollHistoryCache = new Map<string, number[]>();

  private updater: UpdaterFn;

  constructor(updater: UpdaterFn) {
    this.updater = updater;
  }

  public updateCache(key: string, nextValue: number): void {
    const scrollQueue: number[] = [];
    const currentCache = this.scrollHistoryCache.get(key);
    if (currentCache !== undefined) {
      scrollQueue.push(...currentCache);
    } else {
      this.scrollHistoryCache.set(key, []);
    }

    if (scrollQueue.length !== 0) {
      const last = scrollQueue.length - 1;
      const lastValue = scrollQueue[last];
      // if the position to be save differs by a lot from a recently seen position, don't save it
      if (Math.abs(lastValue - nextValue) / lastValue < 0.02) {
        this.updater(key, nextValue);
      }
    }

    const cache = this.scrollHistoryCache.get(key);
    if (cache) {
      cache.push(nextValue);
      // only save a few last events
      if (cache.length > 5) {
        cache.shift();
      }
    }
  }
}
