import * as React from "react";
import { SkeletonProps } from "@mui/material";
import tokens from "@tbml/tokens";
import { LimitedSkeleton } from "./styles";

export type Props = SkeletonProps & {
  forText?: keyof typeof tokens.font;
  maxWidth?: string;
  maxHeight?: string;
};

export function Skeleton({
  forText = undefined,
  height,
  maxWidth = undefined,
  maxHeight = undefined,
  ...props
}: Props): JSX.Element {
  const computedHeight = React.useMemo(
    () =>
      forText
        ? `${
            tokens.font[forText].fontSize.original.value *
            (tokens.font[forText].lineHeight.original.value / 100)
          }${
            tokens.font[forText].fontSize.unit === "pixel"
              ? "px"
              : tokens.font[forText].fontSize.unit
          }`
        : height,
    [forText, height]
  );

  return (
    <LimitedSkeleton
      variant="rectangular"
      animation="wave"
      {...props}
      height={computedHeight}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
    />
  );
}
